import React, { useRef, useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';  

export function UploadDemoVideo(){
 
  const titleInput = useRef(null);
  const urlInput = useRef(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(null);
    const title = titleInput.current.value;
    const url = urlInput.current.value;
    try {
      const docRef = await addDoc(collection(db, "demoVideos"), {
        title: title,
        url: url,
        createdAt: serverTimestamp()
      });
      setSuccess("Demo video added!!")
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    titleInput.current.value = "";
    urlInput.current.value = "";

      
  
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {success !== null && 
            <div className="my-3 bg-green-500 text-white py-3 px-3 text-center">
              {success}
            </div>}
      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input required type="text" ref={titleInput} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Youtube Embed Url</label>
        <input required type="text" ref={urlInput} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />

      </div>
    
      <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">Upload</button>
    </form>
  );
};


