import React, { useRef, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../firebase';  // import the db and storage instances from where you initialized Firebase

export function UploadPaper({ type = 'papers' }){
  const fileInput = useRef(null);
  const titleInput = useRef(null);
  const descriptionInput = useRef(null);
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSuccess(null);

    const file = fileInput.current.files[0];
    const title = titleInput.current.value;
    const description = descriptionInput.current.value;

    const storageRef = ref(storage, `${type}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        
        
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.log('Upload failed', error);
      }, 
      () => {
        setSuccess("Paper added successfully!!")
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          addDoc(collection(db, `${type}`), {
            title,
            description,
            url: downloadURL,
            createdAt: serverTimestamp()
          });
        fileInput.current.value = "";
        titleInput.current.value = "";
        descriptionInput.current.value = "";
        setProgress(0);
          
        });
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {success !== null && 
            <div className="my-3 bg-green-500 text-white py-3 px-3 text-center">
              {success}
            </div>}
      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input type="text" ref={titleInput} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea ref={descriptionInput} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"></textarea>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">{type.charAt(0).toUpperCase() + type.slice(1)}</label>
        <input type="file" ref={fileInput} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
      </div>
      <progress value={progress} max="100" className="w-full h-2"></progress>
      <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">Upload</button>
    </form>
  );
};


