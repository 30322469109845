import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore"; 
import { db } from '../firebase';  // import the db instance from where you initialized Firebase

export function Papers(){
    const [papers, setPapers] = useState([]);

    useEffect(() => {
        const fetchPapers = async () => {
            const papersCollection = collection(db, 'papers');
            const paperSnapshot = await getDocs(papersCollection);
            const paperList = paperSnapshot.docs.map(doc => doc.data());
            setPapers(paperList);
        };
        fetchPapers();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2 mt-24">
            <div className="text-2xl font-bold text-gray-900 mb-6">Research Papers</div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {papers.map((paper, index) => (
                <div key={index} className="max-w-md w-full bg-white shadow-md rounded-lg overflow-hidden mb-4">
                    <div className="p-4">
                        <h1 className="text-gray-900 font-bold text-2xl">{paper.title}</h1>
                        <p className="mt-2 text-gray-600 text-sm">{paper.description}</p>
                    </div>
                    <div className="flex items-center justify-between px-4 py-2 bg-gray-900">
                        <h1 className="text-gray-200 font-bold text-sm">{new Date(paper.createdAt?.seconds * 1000).toLocaleDateString()}</h1>
                        <a className="px-2 py-1 bg-gray-200 text-gray-900 font-semibold rounded hover:bg-gray-300" href={paper.url} download>Download</a>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

