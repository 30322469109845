import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../firebase';  // import the db instance from where you initialized Firebase

export function VideoPage(){
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'demoVideos'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newVideos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setVideos(newVideos);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="p-6 bg-gray-50 min-h-screen mt-24">
      <h1 className="text-4xl font-bold mb-8 text-center">Demo Videos</h1>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {videos.map((video) => (
            
          <div key={video.id} className="rounded shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <div className="relative pb-[56.25%] pt-8 h-0 overflow-hidden">
            {/* <iframe className="embed-responsive-item" src={video.url} title={video.title} allowFullScreen></iframe> */}
            <iframe className="absolute top-0 left-0 w-full h-full" width="560" height="315" src={video.url} title={video.title} allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              {/* <iframe className="top-0 left-0 w-full h-full" src={video.url} title={video.title} allowFullScreen></iframe> */}
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-center">{video.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

