

export function About() {
    return (
        <>
            <div className="p-10 bg-gray-100 min-h-screen flex flex-col items-center mt-24 max-w-4xl mx-auto rounded shadow text-justify">

                <div>
                    <h3 class="text-3xl font-bold dark:text-white">About us</h3>
                    <p class="mb-3 text-gray-500 dark:text-gray-400 font-bold mt-2">We're a team of engineers striving to make a difference through technology. We take on the most complicated, and intractable technology problems.</p>
                    <p class="text-gray-500 dark:text-gray-400 font-bold">Our mission is to provide our customers with extreme value by taking on the toughest engineering problems in the fields of deep learning, computer vision, RADAR/LIDAR vision, signal processing, FPGA and ASIC design and prototyping and more.</p>
                </div>

                <div>
                    <h3 class="text-3xl font-bold dark:text-white mt-6">Core team</h3>
                    <div class="mt-10">
                        <img alt='Nir' src="https://firebasestorage.googleapis.com/v0/b/alephzeroai.appspot.com/o/website_images%2FIMG_4674.jpg?alt=media" width={150}/>
                        <p class="text-gray-500 dark:text-gray-400 font-bold">With a passion for tackling engineering challenges deemed unsolvable, Dr. Nir Regev stands at the forefront of innovation in technology. As the founder and chief scientist of AlephZero, he brings over 26 years of extensive experience in algorithm development. His expertise spans a wide array of specialized areas including radar and lidar signal processing, computer vision, multi-target tracking, radar micro-Doppler, deep learning-based target classification, optimization, and statistical signal processing.
                        <br></br>

He holds a Ph.D. in Electrical Engineering from Ben-Gurion University of the Negev, Israel. His groundbreaking research there focused on radar remote vital signs sensing and the classification and recognition of radar targets.
<br></br>
In addition to his role at AlephZero, Dr. Regev enriches the academic community through his position as an Adjunct Professor at Cal Poly Pomona's Electrical and Computer Engineering Department. Here, he shares his wealth of knowledge and experience, inspiring the next generation of engineers and scientists.</p>
                    </div>
                    <div class=" mt-10">
                        <img alt='Guy' src="https://firebasestorage.googleapis.com/v0/b/alephzeroai.appspot.com/o/website_images%2FGuy%20Profile%20Pic%202021.png?alt=media" width={150}/>
                        <p class="text-gray-500 dark:text-gray-400 font-bold">Veteran of the semiconductor design industry, with over 24 years of both management and hand-on expertise across all technical disciplines of chip, FPGA and Firmware/software design. Extensive experience managing cross-functional HW/SW/FW international projects as well as hands-on experience through all aspects of the chip design/FPGA, from architecture to a product, with a proven track record of successful, time-crunched tape-outs and market intros of flagship products, for various applications ranging from processors, DSPs, DSP accelerators, AI/ML accelerators, vector and array processors, cellular comms base-band SOCs, switch ASICs and more.</p>
                    </div>

                </div>
            </div>
        </>
    )
}