import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase';  // import the db and auth instances from where you initialized Firebase
import {UploadDemoVideo} from '../components/uploadDemoVideo';
import { UploadPaper } from '../components/uploadPaper';
import { NotFound } from '../components/404';
// import {BlogAdminPage} from '../components/uploadBlog';
export function AdminDashboard(){
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const fetchUserData = async () => {
          const docRef = doc(db, 'adminUsers', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setIsAdmin(docSnap.data().isAdmin);
          } else {
            console.log('No such document!');
          }
        };
        
        fetchUserData();
      } else {
        // User is signed out
        setIsAdmin(false);
      }
    });

    // Cleanup subscription
    return unsubscribe;
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen mt-28">
      {isAdmin && <h1 className="text-4xl font-bold text-center my-5">Admin Dashboard</h1>}
      {isAdmin ? (
        <div>
        <div className="grid md:grid-cols-2 gap-6 max-w-7xl mx-auto p-6">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-5">Upload Research Paper</h2>
            <UploadPaper />
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-5">Upload Demo Video</h2>
            <UploadDemoVideo/>
          </div>
          
        </div>
        {/* <BlogAdminPage></BlogAdminPage> */}
        </div>
      ) : (
        <NotFound/>
      )}
    </div>
  );
};


