import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {  signOut } from "firebase/auth";
import {auth} from '../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import logo from "../assets/alephzero_logo.png";

export function Navbar(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext)
      const handleLogout = () => {               
          signOut(auth).then(() => {
          // Sign-out successful.
          dispatch({type:"LOGOUT"})
              navigate("/");
              console.log("Signed out successfully")
          }).catch((error) => {
          // An error happened.
          });
      }
      const {currentUser} = useContext(AuthContext)

    return (
        <nav className="bg-white shadow dark:bg-gray-800 ">
            <div className="container px-6 py-3 mx-auto">
                <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                        <img src={logo} className="mr-3 h-7 sm:h-20" alt="AlephZero Logo"></img>
                            <Link className="text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300" to="/">Alephzero</Link>
                        </div>
                        
                        <div className="flex md:hidden">
                            <button onClick={toggleMenu} type="button" className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                                <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4 5a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm1 7a1 1 0 100 2h14a1 1 0 100-2H5zm0 6a1 1 0 100 2h14a1 1 0 100-2H5z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    
                    <div className={`${isMenuOpen ? 'block' : 'hidden'} md:flex mt-5 items-center`}>
                        <div className="flex flex-col md:flex-row md:mx-6">
                            <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/">Home</Link>
                            <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/about">About</Link>
                            <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/contactUs">Contact</Link>
                            <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/services">Services</Link>
                            {/* <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/waitlist">Courses</Link> */}
                            <Link className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/demoVideos">Demo Videos</Link>
                            
                            {currentUser ? <button className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"  onClick={handleLogout}>Sign Out</button> : <button className="my-1 text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" onClick={() => navigate("/login")}>Login</button>}  
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
