
export function Services() {
    return (
        <>
            <div className="mt-24 p-10 bg-gray-100 min-h-screen flex flex-col items-center max-w-4xl mx-auto rounded shadow text-justify">
                <h3 class="text-4xl font-bold dark:text-white">Our Services</h3>
                <div>
                    <div class="flex items-start mt-10">
                        {/* <img alt="RADAR/LIDAR vision and signal processing" src="https://static.wixstatic.com/media/8660db_bde3a0fb37f54c2081b709412ac8eec6~mv2_d_5000_3333_s_4_2.jpg/v1/fill/w_124,h_83,al_c,q_80,usm_0.66_1.00_0.01/8660db_bde3a0fb37f54c2081b709412ac8eec6~mv2_d_5000_3333_s_4_2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">RADAR/LIDAR vision and signal processing</h4>
                            <div style={{ padding: "10px" }}>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Entire pipeline from detection to tracking and classification.</li>
                                    <li>Range and Doppler estimation.</li>
                                    <li>Direction of Arrival (DOA) estimation.</li>
                                    <li>Super-resolution methods in DOA, range, and Doppler.</li>
                                    <li>Advanced tracking techniques.</li>
                                    <li>Kalman filtering, including Extended Kalman Filter (EKF) and its variants.</li>
                                    <li>Recurrent Neural Network (RNN) based filtering.</li>
                                    <li>Target classification and discrimination using temporal, spectral, and spatial signatures.</li>
                                </ul>
                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Applications Include:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Remote sensing of vital signs.</li>
                                    <li>Drone detection and classification.</li>
                                    <li>Signs of life detection in fires.</li>
                                    <li>Through wall imaging and classification.</li>
                                </ul>
                            </div>
                            {/* <p class=" text-clip">Entire pipeline from detection to tracking and classification including range and Doppler estimation, DOA estimation, super-resolution methods in DOA, range and Doppler, tracking, Kalman filtering (EKF+variants),RNN based filtering, target classification and discrimination using temporal/spectral and spatial signatures. Applications include: remote sensing of vital signs, drone detection and classification, signs of life detection in fires, through wall imaging and classification and more. </p> */}
                        </div>
                    </div>
                    <div class="flex items-start mt-10">
                        {/* <img alt="Computer Vision" src="https://static.wixstatic.com/media/8660db_81393bd4f0e74ccf92cc77b3bc845362~mv2_d_4300_2866_s_4_2.jpg/v1/fill/w_124,h_83,al_c,q_80,usm_0.66_1.00_0.01/8660db_81393bd4f0e74ccf92cc77b3bc845362~mv2_d_4300_2866_s_4_2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">Computer vision</h4>
                            <div style={{ padding: "10px" }}>
                                {/* <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Additional Services Include:</h3> */}
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Image and video segmentation.</li>
                                    <li>Target classification and tracking in image coordinates and in world coordinates.</li>
                                    <li>Video-based vital signs estimator.</li>
                                    <li>3D reconstruction for multiple views.</li>
                                    <li>Stereo-vision.</li>
                                    <li>Deep learning for stereo vision and 3D reconstruction from a single image.</li>
                                    <li>Visual odometry.</li>
                                    <li>Real time camera calibration for autonomous vehicles.</li>
                                    <li>And more.</li>
                                </ul>
                            </div>
                            {/* <p class=" text-clip">Image and video segmentation, target classification and tracking in image coordinates and in world coordinates, video based vital signs estimator, 3D reconstruction for multiple views, stereo-vision, deep learning for stereo vision and 3D reconstruction from a single image, visual odometry, real time camera calibration for autonomous vehicles and more. </p> */}
                        </div>
                    </div>
                    <div class="flex items-start mt-10">
                        {/* <img alt="CUDA realtime implementation" src="https://static.wixstatic.com/media/8660db_dd028424502b47a0ae477612114f83a0~mv2.jpg/v1/fill/w_124,h_54,al_c,q_80,usm_0.66_1.00_0.01/8660db_dd028424502b47a0ae477612114f83a0~mv2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">CUDA realtime implementation</h4>
                            <div style={{ padding: "10px" }}>
                                <p>Achieving optimal performance for real-time algorithms running on GPUs goes far beyond just knowing the Cuda or OpenCL library usage. Different algorithm architectures require specialized tuning, memory access optimization, hidden latencies to consider, etc.</p>

                                <p>We are experienced with working in many domains and can design algorithms from concept to real-time deployment on GPUs, as well as taking existing algorithms and porting them to Cuda on GPUs or other embedded platforms.</p>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Experience Includes:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Radar signal processing.</li>
                                    <li>Wireless and wired communication signals processing.</li>
                                    <li>Computer vision.</li>
                                    <li>Machine learning.</li>
                                    <li>Deep learning.</li>
                                    <li>Electromagnetics.</li>
                                    <li>Linear algebra.</li>
                                    <li>Pattern recognition.</li>
                                </ul>

                                <p>If you have an existing CPU based on non-real time algorithms that need GPU speed up, we do:</p>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Code analysis and profiling.</li>
                                    <li>CPU to GPU code optimization and porting.</li>
                                    <li>Multi-GPU support and more.</li>
                                </ul>

                                <p>We have deep expertise in CUDA and OpenCL for both porting existing prototyped systems and algorithms to designing from scratch.</p>
                            </div>
                            {/* 
                            <p class=" text-clip">Achieving optimal performance for real-time algorithms running on GPUs goes far beyond just knowing the Cuda or OpenCL library usage. Different algorithm architectures require specialized tuning, memory access optimization, hidden latencies to consider, etc.</p>
                            <p class=" text-clip">We are experienced with working in many domains and can design algorithms from concept to real-time deployment on GPUs, as well as taking existing algorithms and porting them to Cuda on GPUs or other embedded platforms.</p>
                            <p class=" text-clip">We have experience in many different algorithmic domains ranging from Radar signal processing, wireless and wired communication signals processing, computer vision, machine learning, deep learning, electromagnetics, linear algebra, pattern recognition</p>
                            <p class="text-clip">If you have an existing CPU based on non-real time algorithms that need GPU speed up, we do: code analysis and profiling, CPU to GPU code optimization and porting, multi-GPU support and more. We have deep expertise in CUDA and OpenCL for both porting existing prototyped systems and algorithms to designing from scratch.</p> */}

                        </div>
                    </div>
                    <div class="flex items-start mt-10">
                        {/* <img alt="Antennas Design and Electromagnetic Simulations" src="https://static.wixstatic.com/media/8660db_f64b14489b634241b472a59e0dc36e2f~mv2.png/v1/fill/w_124,h_51,al_c,q_85,usm_0.66_1.00_0.01/8660db_f64b14489b634241b472a59e0dc36e2f~mv2.png" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">Antennas Design and Electromagnetic Simulations</h4>
                            <div style={{ padding: "10px" }}>
                                <p>Custom antennas development up to 130 GHz, antenna arrays for radars and sensors, IoT antennas and Integrated antennas. Antennas on-chip and, antennas-on-package. EM Modelling and simulations, far-field and near-field antennas testing and characterization, EM analysis of existing antennas and PCB products. Radome Impact simulation on antenna performance.</p>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Services in Phased Array Development Include:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Overall concept development including functionality and specifications.</li>
                                    <li>Arrays system calculations and system optimization.</li>
                                    <li>Link budget calculations.</li>
                                    <li>Front End (FE) SoC architecture and functional block diagram development.</li>
                                    <li>Antenna array development and development support.</li>
                                    <li>Beamforming systems analysis.</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Services in RF Board Design Include:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>System definitions and line up analysis, schematic level, and EM design, layout, prototype bring-up, testing, and full characterization including array calibration.</li>
                                    <li>Discrete components design LNAs, Power Amplifiers (GaN), up/down converters, synthesizers, rectifiers, filters, phase-shifter, attenuators, mixers.</li>
                                    <li>Existing designs schematic and layout review and optimization.</li>
                                    <li>Existing product testing.</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="flex items-start mt-10">
                        {/* <img alt="Deep Learning" src="https://static.wixstatic.com/media/8660db_18f717fd71fd46d58a529ad6493b4be1~mv2_d_4000_4000_s_4_2.jpg/v1/fill/w_124,h_124,al_c,q_80,usm_0.66_1.00_0.01/8660db_18f717fd71fd46d58a529ad6493b4be1~mv2_d_4000_4000_s_4_2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">Deep Learning</h4>
                            <div style={{ padding: "10px" }}>
                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Expertise in Neural Networks Includes:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Convolutional Neural Networks (CNN).</li>
                                    <li>Auto-Encoders.</li>
                                    <li>Recurrent Neural Networks (RNN).</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Applications in Computer Vision and Radar Signal Processing Include:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Semantic segmentation.</li>
                                    <li>Pose estimation.</li>
                                    <li>Key-point detection.</li>
                                    <li>Target detection, classification, and tracking.</li>
                                    <li>Sensor signal processing and sensor fusion.</li>
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div class="flex items-start mt-10">
                        {/* <img alt="Communications" src="https://static.wixstatic.com/media/8660db_2cf495239b524aefbd1495d53e4a2d77~mv2_d_4500_2718_s_4_2.jpg/v1/fill/w_124,h_75,al_c,q_80,usm_0.66_1.00_0.01/8660db_2cf495239b524aefbd1495d53e4a2d77~mv2_d_4500_2718_s_4_2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">Communications</h4>
                            <div style={{ padding: "10px" }}>
                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Expertise in Signal Processing Includes:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Signal detection and synchronization.</li>
                                    <li>Digital correction of the front end impairments.</li>
                                    <li>Digital pre-distortion.</li>
                                    <li>PAPR reduction methods.</li>
                                    <li>Distorted signal recovery.</li>
                                    <li>MIMO processing.</li>
                                    <li>Reverse engineering of communications protocols based on air-recordings on downlink/uplink.</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>We Have Worked with Various Communication Standards, Including:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>802.11 (Wi-Fi and Wigig).</li>
                                    <li>5G.</li>
                                </ul>
                            </div>



                        </div>
                    </div>

                    <div class="flex items-start mt-10">
                        {/* <img alt="Chip/FPGA design" src="https://static.wixstatic.com/media/8660db_fc492710c7644ed28f35f76cc1773df2~mv2_d_4272_2848_s_4_2.jpg/v1/fill/w_124,h_83,al_c,q_80,usm_0.66_1.00_0.01/8660db_fc492710c7644ed28f35f76cc1773df2~mv2_d_4272_2848_s_4_2.jpg" /> */}
                        <div>
                            <h4 class="text-2xl font-bold dark:text-white">Chip/FPGA design</h4>
                            <div style={{ padding: "10px" }}>
                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Expertise in RTL to GDS Implementation Includes:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Timing constraints design and verification, physical synthesis, place and route.</li>
                                    <li>Timing closure for high frequencies {'>'}1Ghz and sign-off, chip finishing and layout verification.</li>
                                    <li>Many successful tapeouts in cutting edge process technologies such as 7nm FF+, 12nm, 16nm, 28nm in TSMC and 14nm GF, and Intel processes as well.</li>
                                    <li>Many chip sizes from small to 700MM^2 (max reticle size).</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Our Services in Architecture Design Include:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>Full SoC architecture and uArchitecture, that includes algorithm design, simulation, implementation.</li>
                                    <li>Design for power, performance and area optimization based on target process and floor plan.</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>We Also Provide:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>RTL Design and UVM constrained-random Verification.</li>
                                    <li>DFT: Architecture design, Scan and MBIST insertion and ATPG, JTAG boundary scan, memory repair considerations in advanced processes as well as memory ECC design – Doing all this with deep consideration for the functionality of the circuit, timing closure, area and power.</li>
                                    <li>RTL to FPGA GateWare: Timing constraints design and verification, Synthesis and Place and Route, timing closure.</li>
                                </ul>

                                <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Project/Program Management for All Technology Projects:</h3>
                                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                                    <li>IP selection and vendor management and agreement negotiations.</li>
                                    <li>Tool flow selection and bring up including vendor selection and negotiations.</li>
                                    <li>Foundry relations and process selection.</li>
                                </ul>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}