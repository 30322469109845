export const VerifyEmailAlert = () => {
    return (
      <div className="fixed top-0 left-0 w-full">
        <div className="m-auto max-w-2xl p-4">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 12a1 1 0 11-2 0 1 1 0 012 0zm-1-10a4 4 0 014 4v3a1 1 0 01-.293.707l-.707.707A1 1 0 0112 11v-2a2 2 0 00-2-2 1 1 0 00-1 1.732 3.982 3.982 0 01-1-.732 4 4 0 014-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Email verification needed
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    Check your email for the verification link to fully activate your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  