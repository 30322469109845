// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB-yTz_KXaxcZBAs8TCurY3h6kkiPwIxEM",
    authDomain: "alephzeroai.firebaseapp.com",
    projectId: "alephzeroai",
    storageBucket: "alephzeroai.appspot.com",
    messagingSenderId: "524672629855",
    appId: "1:524672629855:web:61d66263aaabe554e7efea",
    measurementId: "G-Y5Y9BMP24B"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  export const analytics = getAnalytics(app);

  export const auth = getAuth(app)
  export const db = getFirestore(app);
  export const storage = getStorage(app);
  export default app;