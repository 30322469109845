import React from 'react';

export function NotFound(){
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-gray-600 bg-gray-200">
            <h1 className="text-6xl font-bold">404</h1>
            <p className="text-2xl">Page Not Found</p>
        </div>
    );
};


