import React from 'react';

export function Footer(){
  return (
    <footer className="bg-gray-800 pt-10 sm:mt-10 pt-10">
      <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-left">
        <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Resources
          </div>

          <a href="/papers" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Papers
          </a>
          <a href="/blog" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Blog
          </a>
          <a href="/demoVideos" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Demo Videos
          </a>
          <a href="/services" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
           Services
          </a>
          {/* <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Upgrade Guide
          </a> */}
        </div>

        <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Community
          </div>

          <a href="https://www.linkedin.com/company/alephzero-consulting/" className=" hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            LinkedIn
          </a>
          <a href="https://www.facebook.com/AlephZeroConsulting/" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Facebook
          </a>
          <a href="https://www.youtube.com/channel/UCYAUECIi2Tue2NAFf_7xY6A" className="hover:underline my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            YouTube
          </a>
        </div>
      </div>

        <div className="flex pb-5 px-3 m-auto pt-5 border-t border-gray-500 text-gray-400 text-sm flex-col md:flex-row max-w-6xl">
          <div className="mt-2">
          © 2024 Regev Labs LLC. All Rights Reserved.
          </div>

          {/* <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
            <a href="/" className="w-6 mx-1">
              <i className="uil uil-facebook-f"></i>
            </a>
            <a href="/" className="w-6 mx-1">
              <i className="uil uil-twitter-alt"></i>
            </a>
            <a href="/" className="w-6 mx-1">
              <i className="uil uil-youtube"></i>
            </a>
            <a href="/" className="w-6 mx-1">
              <i className="uil uil-linkedin"></i>
            </a>
          </div> */}
        </div>
      
    </footer>
  );
};


