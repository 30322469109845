import React, { useState } from 'react';
import {  sendPasswordResetEmail   } from 'firebase/auth';
import { auth } from '../firebase';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const sendResetEmail = event => {
    event.preventDefault();
    
      sendPasswordResetEmail(auth,email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => { setEmailHasBeenSent(false) }, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your Password</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={sendResetEmail}>
          {emailHasBeenSent && 
            <div className="my-3 bg-green-400 text-white py-3 px-3 text-center">
              An email has been sent to you!
            </div>}
          {error !== null && 
            <div className="my-3 bg-red-500 text-white py-3 px-3 text-center">
              {error}
            </div>}
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input 
                id="email-address" 
                name="email" 
                type="email" 
                autoComplete="email" 
                required 
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send me a reset link
            </button>
          </div>
        </form>
        <div className="text-center mt-4">
          <a href="/login" className="text-indigo-600 hover:text-indigo-500">Back to sign in page</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
