import React, { useState } from 'react';
import {db} from '../firebase';
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';


const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaValue) {  // make sure captcha is validated
        try {
            const docRef = await addDoc(collection(db, "subscribers"), {
            
              email: email,
              createdAt: serverTimestamp()
          
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
          
      
          setEmail('');
          alert('Thank you for subscribing!');
          navigate("/")
       
    } else {
      alert('Please validate the reCAPTCHA');
    }
  };

  return (
    <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto">
        <div className="max-w-md mx-auto my-10 bg-white p-5 rounded-md shadow-sm">
          <div className="text-center">
            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
              Subscribe to our Newsletter
            </h1>
            <p className="text-gray-400 dark:text-gray-400">
              Get updates about our latest articles and news.
            </p>
          </div>
          <div className="m-7">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label
                  for="email"
                  className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="you@company.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-gray-100 focus:border-gray-300 dark:border-gray-700 dark:text-gray-300 dark:focus:ring-gray-900 dark:focus:border-gray-500 dark:bg-gray-700"
                />
              </div>
              <div className="mb-6">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={value => setCaptchaValue(value)}
                />
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
