import React, { useState } from 'react';
import blog1_image from "../assets/blog_images/reset synchronizer.png";
import blog2_image1 from "../assets/blog_images/img1.png";
import blog2_fig1 from "../assets/blog_images/fig1.png";
import blog2_fig2 from "../assets/blog_images/fig2.png";
import blog2_fig3 from "../assets/blog_images/fig3.png";
import blog2_fig4 from "../assets/blog_images/fig4.png";
import blog3_fig1 from "../assets/blog_images/Picture1 (3).png";
import blog3_fig2 from "../assets/blog_images/Picture1 (2).png";
import blog4_image from "../assets/blog_images/IMG_2504.jpg";
import blog4_img_show from "../assets/blog_images/doa.png";

import blog5_gif from "../assets/blog_images/generic_asic_flow.gif";
import blog5_fig1 from "../assets/blog_images/Picture1 (7).png";
import blog5_fig2 from "../assets/blog_images/Picture1 (6).png";
import blog5_fig3 from "../assets/blog_images/Picture1 (5).png";
import blog5_fig4 from "../assets/blog_images/syn.png";
import blog5_fig5 from "../assets/blog_images/verif.png";
import blog5_fig6 from "../assets/blog_images/img2.png";

import blog6_image from "../assets/blog_images/DOAExample_01.png";

import blog7_fig1 from "../assets/blog_images/fig1 (1).png";
import blog7_fig2 from "../assets/blog_images/fig2 (1).png";
import blog7_fig3 from "../assets/blog_images/fig3 (1).png";
import blog7_fig4 from "../assets/blog_images/Untitled (2).png";

import blog8_image from "../assets/blog_images/blog8.jpg";
import blog8_fig1 from "../assets/blog_images/img.png";
import blog8_fig2 from "../assets/blog_images/Untitled (3).png";

const Blog = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  // Static blog data with images and multiple paragraphs
  const blogs = [
    {
      id: 1,
      title: 'Asynchronous RESET timing-closure - hell or heaven? (Part I)',
      image: blog1_image,
      content: (
        <>
          <p className="mb-4">In large SoCs (system-on-a-chip) or relatively slow FPGAs properly distributing the asynchronous reset lines to all the flops is a relatively important and big problem that should be architectured and planned before design even starts.</p>
          <p className="mb-4">During my career, I personally inherited some legacy chips or designs in which this problem wasn’t thought through from the beginning, leaving the designers fighting place and route, layout and timing closure issues, costing months in schedule, damaging the morale of the team and damaging the entire company which is trying to tape-out its product. This is a hellish scenario since many of these problems, without proper re-design and planning, are just unsolvable in recent low geometry processes, where the OCV (on-chip-variation) that is factored into the timing calculation becomes more dominant. </p>
          <p className="mb-4">In order to understand the problem better, we first need to understand what kind of timing checks, PrimeTime, or any other STA (static timing analysis) tools will make in order to determine whether the asynchronous reset into the flop, is meeting timing.</p>
          <p className="mb-4">In a normal flip-flop, the activation or assertion of the reset line will make the flop output go to its reset/default value (most often ‘0). While the reset line is asserted the flop will keep this value on the output and ignore the clock and data-input lines. Once the reset line is deasserted the flop should resume normal behavior in which the data-input line will propagate to the data-output line on the clock-edge (rising edge for positive-edge triggered flip-flops). Herein lies the problem! In essence, when reset is being deasserted, a race between the clock and the reset signal is taking place. This is true for all the flops that are tied to the same reset and the same clock. The designer's intention is that all the flops have the intended default value when coming out of reset (reset deassertion), for at least one clock cycle. If a state machine should be initialized (reset state) to all zeros and one flop in this state machine comes out of reset with the value of ‘1, a serious silicon bug can occur.</p>
          <p className="mb-4">This is why timing tools will check recovery and removal for the reset line.</p>
          <p className="mb-4">Recovery check: This checks that the reset de-assertion signal arrives and gets stable at least a recovery-time (recovery-time is the Flip-Flop’s recovery time and is defined in the timing library of the flop) before the active clock edge. This check is equivalent to a normal setup check between the data and the clock, only the check here is between the reset and the clock.</p>
          <p className="mb-4">This check is equivalent to a normal check between the data clock, only the check here is between the reset and the clock.set synchronizer circuit usually). The timing check is checking that the reset signal is stable at least removal-time after the active clock edge. This check is equivalent to a normal hold check between data clock, only the check here is between the reset and the clock.</p>
          <p className="mb-4">Since the de-assertion of the reset signal is being timed against the clock signal of the flops, it needs to be synchronized to it. There are a few common reset synchronizers designs, below is one example, but their idea is always the same: </p>
          <p className="mb-4">Transfer the assertion of the reset asynchronously to all the flops in the reset network.Synchronize the deassertion reset edge to the common clock so all flops leave the reset state and resume normal functionality at the same time.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog1_image} alt='blog1' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 1: Reset Synchronizer</figcaption>
          </figure>
          <p className="mb-4">One can immediately notice that if the domain that this reset-synchronizer is driving is large (i.e. a lot of flops occupying large area), one would have a problem with getting timing closed for this reset network. This kind of design, unless very localized, will not work well in deep-submicron technologies where the OCV between the reset and the clock would penalize timing for both recovery and removal (setup and hold) checks.</p>
          <p className="mb-4">In part 2, I will outline an architecture that solves this problem, and basically completely removes the need to close timing on the reset lines.</p>
          <p>Stay tuned!</p>
        </>
      ),
    },
    {
      id: 2,
      title: 'Understanding overfitting',
      image: blog2_image1,
      content: (
        <>
          {/* <p className="mb-4">-</p> */}
          <img alt='blog2' src={blog2_image1} />

          <p className="mb-4">Overfitting is a frequently used term in the data science community; yet the most common explanation or “definition” that I hear regarding what Overfitting is usually goes something like this: “when the model learns the noise” or “when the model accuracy on the training set is very high but very low on the test set”.</p>
          <p className="mb-4">First, let’s focus on the term “fitting”. Fitting in mathematics (i.e., curve fitting) is the process of finding a mathematical function that best describes (fits) a plurality of data points. Best in what sense? That’s outside the scope of this blog post, but suffice it to say that we are trying to minimize some error in order to achieve the most precise fit.</p>
          <p className="mb-4">Hence, Overfitting is the term that is used to describe the application of a function that “perfectly” fits the data. Perfect in what sense? ~Zero error (or ~100% accuracy). The quotes are used to denote the fact that although the error is close to zero and the accuracy is apparently sky high, and the function is “too complex” for the data and hence the overfit. Let me illustrate that.</p>
          <p className="mb-4">Consider an underlying linear model, as illustrated in Fig.1 below.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog2_fig1} alt='blog2' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Fig.1 - The data - The underlying model is linear. (y = 2x).</figcaption>
          </figure>
          <p className="mb-4">Further, suppose that we have only observed five points of this process (as depicted in Fig.2 below).</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog2_fig2} alt='blog2' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Fig.2 - We observe only 5 points of the above process</figcaption>
          </figure>
          <p className="mb-4">Now, let’s assume that we train a polynomial model of order 5. Then we’ll get a perfect fit, as seen in Fig.3.</p>
          <p className="mb-4">But since we know that the underlying model is a polynomial of order 1 (linear), we know that the trained model is far from representing the data accurately. Fig. 4 below shows exactly that.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog2_fig3} alt='blog2' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Fig.3. - Data is overfitted by a polynomial of order 5.</figcaption>
          </figure>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog2_fig4} alt='blog2' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Fig.4 - The “overfitted” model does not represent the data. We know that the best model here is linear.</figcaption>
          </figure>
          <p className="mb-4">That is the problem of overfitting. We see that in a lot in deep learning applications because we generally have a lot of more trained parameters than training data points.

            We can consider some ways to overcome the problem of overfitting:</p>

          <ol className="list-decimal list-inside">
            <li className='mb-2'>
              Shrink the model size - There’s a significant body of work on a topic called “model order selection”. I refer the readers to an excellent <a href='http://www.sal.ufl.edu/eel6935/2008/01311138_ModelOrderSelection_Stoica.pdf' className=' text-cyan-400'>paper</a> on this topic.
            </li>
            <li className='mb-2'>
              Regularization - Not a new subject of research, and certainly not unique to machine learning. (e.g. Tikhonov regularization)
            </li>
            <li className='mb-2'>
              Get more data. (synthetic, augmentation, etc.)
            </li>
          </ol>
          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Nir Regev is a co-founder of AlephZero Consulting. He is a senior  research scientist, with over 21 years of experience in developing algorithms. Loves engineering problems that are unsolvable. Specializing in computer vision, radar signal processing, multi-target tracking and deep learning, classification, radar micro-Doppler, deep learning based target classification, optimization and statistical signal processing. </p>

        </>
      ),
    }, {
      id: 3,
      title: 'MIMO-Radar signal processing chain',
      image: blog3_fig1,
      content: (
        <>
          <h1 className='font-black text-2xl mt-8 mb-4'>Introduction – System overview</h1>
          <p className="mb-4">In this paper we provide a short overview of a modern MIMO radar system. The block diagram of the considered system is shown in the Fig. 1.</p>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog3_fig1} alt='blog3' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 1: MIMO radar signal processing pipeline</figcaption>
          </figure>

          <p className="mb-4">The system contains the following blocks:</p>
          <p><b>- TX processor:</b> This block generates the waveform which is transmitted by the radar. In this document will focus on FMCW waveforms [GR1] which are commonly used in modern radars. For MIMO radar, there are two major approaches for transmission through multiple TX antennas (the number of TX antennas is Ntx):</p>
          <ol className="list-decimal list-inside">
            <li className='mb-2'>
              Time division multiple access (TDMA) - Transmission of the same waveform through multiple antennas, but at the different time instances, i.e. at the beginning the 1st TX antenna transmits the waveform and all other TX antenna are switched off, then the 2nd TX antenna transmits, while the rest are switched off, etc.  See Fig. 2 (a).
            </li>
            <li className='mb-2'>
              Frequency division multiple access (FDMA) – Multiple orthogonal waveforms are transmitted through all the antennas simultaneously. In FDMA approach the orthogonally is achieved by the frequency shift of the waveform. See Fig. 2 (b).
            </li>

          </ol>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog3_fig2} alt='blog3' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 2 - MIMO FMCW waveforms</figcaption>
          </figure>

          <p className='mb-4'><b>- RF front end:</b> This block serves as a bridge between analog signals and digital signals. For the TX chain, this block converts digital signals to RF signals, amplifies them and send them to the antennas. For RX chain, the block receives signals from the RX antennas, amplifies and filters them, and converts them to digital samples. Commonly, for FMCW radars, the RX signals are mixed with the TX signals to extract the beat frequency, which translates directly to range</p>
          <p className='mb-4'><b>- MIMO RX processing: </b>This block receives signals from Nrx RX channels. Using the orthogonality property of the TX waveforms, this block extracts Ntx signals from each RX channel. The total number of signals after MIMO processing is Ntx * Nrx, these signals can be seen as the output of a virtual array of size Ntx * Nrx.</p>
          <p className='mb-4'><b>- Range-Doppler processing:</b> This block estimates target distances by performing spectral analysis of the beat frequencies. In addition, by transmitting a sequence of FMCW pulses, we can track the phase change of the reflections from each target. For moving targets this phase change can be modeled as a complex exponent which frequency is known as the Doppler frequency. These Doppler frequencies are associated with the target radial velocities and they are estimated by the Range-Doppler processor. The output of the Range-Doppler processor is a 2D map where targets are represented by energy peaks. For a MIMO radar, the Range-Doppler processing is applied for each (virtual) channel.</p>
          <p className='mb-4'><b>- Detection:</b> This block detects peaks in the Range-Doppler map which are associated with potential targets. The output of this block is a list of potential targets with their ranges and radial velocities.</p>
          <p className='mb-4'><b>- Direction of arrival (DOA) estimation: </b>This block estimates direction of each potential target. In order to do this, it uses signals received from the multiple channels (virtual MIMO channels). The DOA estimation is based on the fact that the phase change of the signals at the different channels is due to  direction of the target. This phase change is associated with a spatial frequency of the targets which should be estimated by the DOA algorithm. More on direction of arrival estimation can be read on in this post.</p>

          <p className='mb-4'><b>- Tracker:</b> This block receives list of potential targets with their range, velocity and DOA. Using history of the previous location (and velocity) of the targets and current measurement, the block estimates the current location and velocity of each target.</p>

          <p className='mb-4'><b>- Target Classification Processing:</b>
            This is the part that uses past and present features gathered on each tracked target in order to classify the target. For example differentiating between a pedestrian and a bicycle on the road, a car or a truck, drone or bird, etc. Example for features can be motion trajectory pattern, RCS fluctuation, micro-Doppler signatures and more.</p>
          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Ilia Yoffe is a principal research scientist in AlephZero Consulting. He is an Algorithms scientist with extensive industry experience. Holds a Ph.D. in electrical engineering. Published more than 15 papers in top journals and conferences. Specializing in digital communication (physical layer), spatial signal processing and radar signal processing. Experienced with machine learning, deep learning, computer vision. Experienced with communications standards including 802.11 (Wi-Fi and Wigig) and 5G, as well as reverse engineering communications protocol based on air-recordings on downlink/uplink.

          </p>


        </>
      ),
    },
    {
      id: 4,
      title: "On The Sensitivity of direction of arrival (DOA) Estimation to Baseline Inaccuracies",
      image: blog4_img_show,
      content: (
        <>
          <img alt='blog4' src={blog4_image}></img>
          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Nir Regev is a co-founder of AlephZero Consulting. He is a senior  research scientist, with over 21 years of experience in developing algorithms. Loves engineering problems that are unsolvable. Specializing in computer vision, radar signal processing, multi-target tracking and deep learning, classification, radar micro-Doppler, deep learning based target classification, optimization and statistical signal processing. </p>


        </>
      )
    },
    {
      id: 5,
      title: "High level introduction to ASIC design",
      image: blog5_gif,
      content: (
        <>
          <p className='mb-4'>Some claim that designing chips, or ASICs (Application Specific Integrated Circuits) is an art form bordering on black magic. While for those not skilled in the art, it might appear that way, for those experts who are highly skilled in the craft, it is nothing more than a very well defined and well rehearsed, although very complicated, process that takes you from an idea, to a functional working chip that you can ship and sell to your customers.</p>
          <p className='mb-4'>This is an attempt at introducing this art form to those who are wondering how an ASIC chip is designed, but are outside this incredible and costly world of chip design.</p>
          <p className='mb-4'>An ASIC stands for Application Specific Integrated Circuit, which in simple words means: an integrated circuit, which is a big circuit that performs a specific function or functions and is integrated into a silicon chip. An SoC stands for a system-on-a-chip which means an entire system that might include many types of functionality that used to be performed by many chips is now crammed onto a single chip. For example, a cellular baseband processor chip like everybody has inside their cell phones is an ASIC SoC designed to perform voice and data mobile communications. ASIC Design, or IC Design, or chip design, are all different terms to describe the sequence of steps of taking an idea or a concept, and going through the process of designing millions of small electronic circuits that go inside, a silicon chip, that performs the functions that the concept or idea called for.</p>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig1} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 1: one of the first computers taking a whole room, made of vacuum tubes (prior to transistor invention). Equivalent functionality of 5000 transistors</figcaption>
          </figure>
          <p className='mb-4'>The invention of the transistor and the subsequent ability to consistently minimize (shrink) its size, made it possible to cram the functionality equivalent of 200,000 Figure-1 rooms into a small chip as shown in Figure-2 below.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig2} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 2: Exponential shrinking in transistor size made this possible within the span of 50 years</figcaption>
          </figure>
          <h1 className='text-2xl font-black mb-4'>How the ASIC chip is  designed</h1>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_gif} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 3: ASIC design generic flow diagram</figcaption>
          </figure>

          <p className='mb-4'><b>1. Architecture Design.</b> In this stage, the designers are specifying at a high level what functionality they would like the chip to perform. Later on, this functionality or architecture document will be used to derive a more low-level architecture called micro-architecture, where every function will be broken down into sub-functions and sub-building blocks. You can think of it as building a house, first, you draw the high-level architecture, shapes, window and door locations, etc, and later you plan for plumbing, electricity, etc based on the high-level architecture and where everything is located. This stage is heavily influenced by marketing obviously since a lot of the features or functionality is requested or vetted by the customers.</p>
          <p className='mb-4'><b>2. Choosing the manufacturing technology (or the process node).</b> In this stage, the ASIC team needs to decide which silicon manufacturing technology it will target during the design process. This is a critical decision that takes into account many factors such as: <b>a) IP</b> (Intellectual Property) availability. What building blocks does the designer have to work within this process node? Going back to our house example, what pre-fab windows, doors, cabinets, etc. can I choose from to quickly and with high quality build my house. <b>b) Price.</b> The more advanced the node, the more expensive it is, but the smaller the silicon chip will be. I.e., if the volume of your product is significant (10’s of millions a year), you might gain from going with a smaller node. <b>c) The complexity of the design.</b> The more advanced the node, the more complex and time consuming it is to design into it.</p>
          <p className='mb-4'><b>3. HDL Implementation.</b> HDL stands for Hardware Description Language. Which is basically a language that is used to describe hardware at a high level. The most commonly used languages for this are Verilog System-Verilog and VHDL. Hardware designers, AKA logic designers, basically program in these languages but the result (after a process called synthesis), is hardware circuits, rather than software. These high-level languages allow designers to quickly describe very large amounts of hardware (or logic), with very little code, which helps readability as well as verification and debug.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig3} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Example of a short code written in VHDL and Verilof, two popular HDL languages</figcaption>
          </figure>
          <p className='mb-4'><b>4. Synthesis.</b> A set of software tools controlled by engineers that read in the HDL and other relevant inputs and produces a circuit description composed of standard components like gates (AND, NAND, OR, NOR, etc.) that implements the functionality described by the designer in the HDL. </p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig4} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Synthesis - reads in the high level code (upper window) and spits out a circuit (lower window)</figcaption>
          </figure>

          <p className='mb-4'><b>5. Logic Verification.</b> This is a process by which the HDL or the hardware description is verified against the specifications. This is one of the most critical and time-consuming processes in chip design. A lot of standards and non-standard verification methodologies came and went, and some are still dominant in the market. Choosing the right set of methodologies for your project is a delicate trade-off between schedule (100% verification takes infinite time), and quality, you need to be very confident that the chip you’re taping-out (i.e., send to production) is going to come back working.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig5} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">verification flow and simulation signal waveform debug - common task for a logic design engineer during debug</figcaption>
          </figure>

          <p className='mb-4'><b>6. Place & Route</b> - a semi-automatic process done by engineers and other software tools, by which the physical connectivity and placement (i.e., layout) of each every cell in the chip is done.</p>
          <p className='mb-4'><b>7. Physical Verification.</b> Verifying that the physical layout does not violate the fab’s design rules. If it does, that can cause chips that don’t work, yield issues, and more.</p>
          <p className='mb-4'><b>8. Tape-out.</b> After all the previous stages are done and signed-off by the team, you are ready for tape-out, and you send out the full chip database to the fab for fabrication.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog5_fig6} alt='blog5' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Using a very complicated process which is the result of decades of scientific advance and research, the foundry manufactures the chip</figcaption>
          </figure>
          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Guy Regev is a co-founder and Managing Partner of AlephZero Consulting. He is a veteran of the IC design Industry, with over 20 years of both management and hand-on expertise across all technical disciplines of chip and FPGA design. Extensive experience managing cross-functional HW/SW/FW international projects as well as hands-on experience through all aspects of the chip design/FPGA and productizing, with a proven track record of successful, time-crunched tape-outs and market intros of flagship products. He is also an Expert Witness for cases that involve hardware, chip, IC Design, SoC Design or FPGA design, as well as EDA tools, software, and embedded firmware. More about him at: <a className=' text-blue-500' href='https://www.guyregev.com/'>https://www.guyregev.com/</a> </p>

        </>
      )
    },
    {
      id: 6,
      title: "On Direction of Arrival Estimation with 1-bit Quantizer (Radar SIgnal Processing)",
      image: blog6_image,
      content: (
        <>
          <p className='mb-4 mt-4'>Abstract—Designing and implementing analog front-end circuits is a complex problem and thus, is the cornerstone of any radar system design. We propose removing the gain control block, as well as reducing the complexity by introducing a 1-bit analog to digital converter (ADC) at the receiving path. Nevertheless, this nonlinear quantization operation distorts the signal in a way that does not preserves its Gaussianity, rendering the common Maximum Likelihood (ML) based Direction of Arrival (DOA) estimation methods non-optimal. We derive the ML optimal DOA estimator for the 1-bit ADC and propose sub-optimal, yet, effective estimator to reduce the complexity of the ML estimator. We benchmark the performance of the proposed estimators derived in this paper against the derived Cramér–Rao lower bound and investigate the case of a known and unknown transmitted signals. We show that the proposed algorithms attain the bound under various conditions as well as outperform a naïve ML approach for the 1-bit ADC problem.</p>
          <h1 className='mb-4 text-3xl font-black'>I. Introduction </h1>
          <p className='mb-4'>The implementation complexity of analog front-end circuits is a major hurdle in the design of sensors array. In order to reduce this complexity, it is proposed to apply 1-bit quantizer at the receiving path of each antenna, thus, enabling the removal of the gain control block as well as simplification of quantizer’s structure, as it can be implemented by a single comparator. This simplification enables simple and inexpensive implementation of receiving array with a very large number of antennas.</p>
          <p className='mb-4'>Nevertheless, a serious drawback of a 1-bit Analog to Digital Converter (ADC) is that it performs a highly nonlinear and noninvertible operation on the original analog receive signal. As a result of this operation, the common assumption of the Gaussianity of the received signal is violated. In fact, the received signal is turned into a binary correlated sequence. The distribution of this sequence is known as multivariate orthant probability. Unfortunately, closed-form expressions are available only for maximal sequence length of 4 [1].</p>
          <p className='mb-4 '>Several studies related to 1-bit quantization are found in the literature. The issue of frequency estimation with a 1-bit quantizer is studied in [2]. This problem has a similar formulation as Direction of Arrival (DOA) estimation. The presented analysis assumes a deterministic signal model with unknown parameters.</p>
          <p className='mb-4 '>DOA with 1-bit quantizer with random signals is studied in [3]. Due to the complexity of the problem the full analytical analysis is only presented for 2 sensors. Moreover, several sub-optimal methods for DOA estimation were proposed for an M-sensors Uniform Linear Array (ULA). These methods are based on the reconstruction of the covariance matrix of the original signal before the clipping.</p>
          <p className='mb-4 '>In [4] the DOA estimation problem with a 1-bit quantizer of random signals is considered. Here, a nonlinear transformation is applied on the received signal after quantization. This transformation is re-generating an approximately Gaussian distributed signal, which is then used in a Maximum Likelihood (ML) framework for estimation and performance evaluation. In [5] the authors reported the results of 1-bit DOA estimation for sparse nested and coprime arrays while in [6] a generalized sparse Bayesian learning algorithm is used iteratively as a two-step estimator, namely, a sparse Bayesian learner followed by a naïve Minimum Mean Squared Error (MMSE) estimator of a linear problem. The same problem is studied in [7] for the Sparse MIMO array setting and is employing a support vector machine (SVM) approach. In this work, we derive DOA estimation methods for ULA with 1-bit quantizer for deterministic known and unknown signal. We do not assume sparsity. In Section II we assume a single source and deterministic known signal. We derive the ML DOA estimator and propose a sub-optimal low complexity estimator based on 1 bit FFT. The performance of the estimators is benchmarked against the numerically calculated Cramér–Rao Bound (CRB) for an ideal quantizer case (ML and CRB).  In Section III we assume a single source and deterministic unknown signal. We derive an Expectation Maximization (EM) based method for DOA estimation. Moreover, we study the performance degradation of the existing methods, as naïve ML and MUSIC, in the case of 1-bit quantization.</p>
          <p className='mb-4 '>Full paper: <a className=' text-blue-600' href='https://www.alephzero.ai/papers'>https://www.alephzero.ai/papers</a></p>
          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Nir Regev is a co-founder of AlephZero Consulting. He is a senior  research scientist, with over 21 years of experience in developing algorithms. Loves engineering problems that are unsolvable. Specializing in computer vision, radar signal processing, multi-target tracking and deep learning, classification, radar micro-Doppler, deep learning based target classification, optimization and statistical signal processing. </p>
          <p className="mb-4 font-medium">Ilia Yoffe is a principal research scientist in AlephZero Consulting. He is an Algorithms scientist with extensive industry experience. Holds a Ph.D. in electrical engineering. Published more than 15 papers in top journals and conferences. Specializing in digital communication (physical layer), spatial signal processing and radar signal processing. Experienced with machine learning, deep learning, computer vision. Experienced with communications standards including 802.11 (Wi-Fi and Wigig) and 5G, as well as reverse engineering communications protocol based on air-recordings on downlink/uplink. </p>
        </>
      )
    },
    {
      id: 7,
      title: "Reduction in ATE Test time for Core Wrapped blocks by Avoiding Q->SI atspeed timing",
      image: blog7_fig1,
      content: (
        <>
          <h1 className='font-bold text-2xl mt-8 mb-4'>What is the problem being solved by this work, or future challenge addressed?</h1>
          <p className='mb-4'>Increasing design sizes and scan shift power limit is forcing designers to test physical blocks separately on ATE. Current DFT insertion tools offer a core wrapping feature which is used to wrap physical blocks, in order to a) test them stand-alone and b) to enable testing of faults between blocks. This feature is also used to avoid interface X sources. The main issue with current solutions is that, it needs designers to close at-speed timing for Q->SI paths for all these registers to avoid X sources. This issue pertains to all projects which have their blocks core wrapped using current art DFT insertion tools. Q->SI paths at-speed timing closure where the registers are physically far apart in the block can over-constrain a high speed design, and will hurt the real functional timing arcs, moreover, Q->SI timing arc between two different synchronous clock domains is difficult to time. Not closing timing for Q->SI paths will create X sources which will increase ATE vector count, test time and cost. Our main challenge was to implement an automatic work around scheme which current DFT insertion tools support while not disturbing any existing scan insertion flows.</p>
          <h1 className='font-bold text-2xl mb-4'>What specifically is new in this work? </h1>
          <p className='mb-4'>We present an innovative solution for this problem by offering an automatic flow that generates customized structure of shared wrapper cells that is used for Core wrapping solution. Current DFT insertion tools are also discussed in comparison to our solution.</p>

          <h1 className='font-bold text-2xl mb-4'>Abstract</h1>
          <p className='mb-4'>Current solutions have 2 built-in cell types which are used to wrap the ports of the physical blocks. A “dedicated wrapper cell” which is in general used to register slow input/output ports, here we break the functional timing path by creating new register cell and test the faults. A “shared wrapper cell”: If we want to test the path for real speed, we will have reuse the existing functional registers in the wrapper chains as “shared wrapper cells”.</p>
          <h2 className='font-medium text-lg mb-4'>Current tool Implementation of Shared Wrapper Cells</h2>
          <p className='mb-4'>There is an enhanced core wrapping feature which reuses functional registers as shared wrapper cells, based on user controlled parameters like combinational depth limit, max fanout limit from the ports. This feature is also a must use for SLOS designs. Functional registers talking with input ports will be stitched in input wrapper chains with IWRAP_SE as scan enable and functional registers talking with output ports will be stitched in output wrapper chains with OWRAP_SE as scan enable. </p>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog7_fig1} alt='blog7' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 1 - Current Implementation of Shared Wrapper Cells. (If IWRAP SE is high, we have to close unnecessary Q to SI Path for high speed to avoid X sources)</figcaption>
          </figure>

          <p className='mb-4'>Fig 1 shows the structure of shared wrapper cells implemented by the toold. Here we can see that when the block is in INTEST mode (test for targeting faults inside the block), we have to keep IWRAP_SE to 1’b1 during capture to avoid X sources from the ports. This creates Q->SI timing arc which needs to be timed and closed for functional speed. For Stuck-at (usually relatively slow) the Q->SI timing arc is similar to Shift Q->SI in most of the cases, so it is not an issue. </p>
          <p className='mb-4'>But for transition faults Q->SI timing closure for a high speed clock can be big issue. Closing these non-functional, but full speed paths can prove difficult, especially if the chain registers are physically far apart due to functional reasons, and touching them or moving them for test purposes might hurt functional timing. If we plan to mask the flops for transition or allow X sources into the system, we increase the patterns count & lose coverage, and the whole intended benefit from the core wrapping solution is not completely utilized.</p>

          <h2 className='font-medium text-lg mb-4'> Custom Structure for Shared Wrapper Cells</h2>
          <p className='mb-4'>Fig 2 shows the custom implementation for the shared wrapper cells, here there will be an additional mux in the SI path to break the Q->SI timing arc and loop the existing (previous) value of the flop for transition fault testing scenarios where X sources need to be avoided. Scan enables will be used like a regular scan enable where it will be 1’b0 in capture when we want to avoid Q->SI at speed arc. Depending on Synchronous Launch on shift (SLOS) or Launch on capture (LOC) method of at speed testing in the design, we can implement the changes either closer to each and every shared wrapper cell in case of SLOS or can have single circuit for whole wrapper chain and save gate count in case of LOC.</p>
          <p className='mb-4'>There are couple of ways to implement the discussed custom shared wrapper cell implementation:</p>
          <p className='mb-4'>1. By defining a custom cell to replace the default shared wrapper cell implemented by the tool. This particular method has tool limitation since the tool won’t allow us to use cell swap feature combined with their max_reuse feature. We are in the process of working with the tool vendors to incorporate the change for next tool revisions. </p>
          <p className='mb-4'>Fig3 shows the structure and the commands for defining this.</p>
          <p className='mb-4'>2. In order to work around the tool limitations mentioned above we developed an automatic insertion flow described below:</p>


          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog7_fig2} alt='blog7' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 2 - Custom Modification of Shared Wrapper Cells. (Shared wrapper cell holds value avoiding both, need for closing Q-> SI at-speed timing arc & avoid X sources)</figcaption>

          </figure>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog7_fig3} alt='blog7' />
            {/* Add a caption to the image */}
            <figcaption className="text-sm text-gray-500 italic">Figure 3 - Optimized Custom Shared wrapper cell. (Default structure used for SLOS designs from TCL script)</figcaption>

          </figure>
          <h2 className='font-medium text-lg mb-4 mt-4'>TCL Script Based Automated Solution to avoid Q->SI path:</h2>
          <p className='mb-4' >TCL procedure script is developed and is sourced after the execution of {"<insert_dft>"} command in DFT compiler. The rest of the existing scan insertion flow (script(s)) needs no change for our solution to work. The TCL Procedure automatically collects the shared wrapper cell information in the design and implements the fix as shown in Fig2, while using user provided Intest and Extest enable pin path information to make all the necessary connections. All the new cells are implemented in the same hierarchy as the shared wrapper cell, which makes it easier to close timing. Scandef will automatically contain (“remember”) the new mux structure and can be used for scan chain reordering with no problems. The TCL script can support any process technology. Designs with existing false path to the SI pin of the shared wrapper cells in functional timing mode will be modified to enable new loop back path timing arc and also path from scan enable pipeline register in case of SLOS based designs. TCL script will automatically take care of these requirements with modify_si_timing switch.</p>
          <p className='mb-4'>Usage: <b>q2sifix</b> # Fix Q->SI transition path for core wrapper (border sealed) block</p>
          <p className='mb-4'><b>-intest_enable</b> {"{Required INTEST_MODE Enable Internal Pin/Port}"}</p>
          <p className='mb-4'><b>-extest_enable</b>{"{Required EXTEST_MODE Enable Internal Pin/Port}"}</p>
          <p className='mb-4'><b>-test_mode</b> {"{Optional test mode name where wrapper chains are active. Default: Internal_scan}"}</p>
          <p className='mb-4'><b>-modify_si_timing</b> {"{Optional enable/disable where SI timing constraints are modified automatically. Default: disable}"}</p>

          <h1 className='font-bold text-2xl mt-8 mb-4'>Results</h1>
          <p className='mb-4'>This new flow has already been tested using DFT compiler and we have successfully avoided Q->SI paths for at-speed testing. We are seeing a major gain from reduction in at speed pattern count, and also slight improvement in coverage compared to the design where we allowed X sources into the design by opting not to close Q->SI at speed timing arc. X sources in designs with scan-compression implemented, will reduce observability and increase the ATE vector count. We have seen about 10% to 30% decrease in pattern count varying between designs, when using the custom shared wrapper cells. There is also improvement in test coverage of about 0.05% to 0.15%. Pattern count reduction can be even higher if we skip this additional coverage improvement for baseline comparison.</p>
          <p className='mb-4'>Following table shows block coverage/pattern numbers as comparison between schemes.</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog7_fig4} alt='blog7' />
            {/* Add a caption to the image */}
            {/* <figcaption className="text-sm text-gray-500 italic">Figure 3 - Optimized Custom Shared wrapper cell. (Default structure used for SLOS designs from TCL script)</figcaption> */}

          </figure>
          <p className='mb-4'>The idea we are proposing is a re-design of the core wrapping shared cell in order to avoid the “parasitic” non-functional at-speed paths while not creating any X sources and thus keeping the pattern count as small as possible. On top of redesigning the wrapper cell, we have also implemented an automatic flow that inserts these cells automatically given any design, and with almost no interference to the current project scan insertion flow/scripts.</p>
          <p className='mb-4'>Results: We are seeing about 10-30% reduction in at speed pattern count, and also slight improvement in coverage of about 0.05%-0.15% compared to the design where we allowed X sources into the design by opting not to close Q->SI at speed timing arc. Since the overall test content of any product is heavily dominated by at-speed content, the % savings in final test time may be higher.</p>
          <p className='mb-4'>This issue pertains to all projects which have their blocks core wrapped (border sealed) using current DFT insertion tools. Q->SI paths timing closure where the registers are physically far apart in the block can over-constrain a high speed design, and will hurt the real functional timing arcs. Moreover, Q->SI timing arc between two different synchronous clock domains is difficult to time properly. Not closing timing for Q->SI paths will create X sources which will increase ATE vector count, test time and cost.</p>
          <h2 className='font-medium text-lg mb-4 mt-4'>Conclusion</h2>
          <p className='mb-4'>To summarize, we present a design and an automatic flow that is solving a limitation in current DFT insertion tools. The limitation is that the implementation they chose for core wrapping (border sealing) introduces “parasitic” undesirable non-functional at-speed timing path that if the designer doesn’t close will hurt test coverage and pattern count. </p>
          <p className='mb-4'>By designing a new core-wrapping cell to be used instead of the DFT Compiler shared wrapper cell, we have eliminated the unwanted non-functional at-speed Q->SI paths. By doing that we have improved two things: 1. eliminated the need to close at-speed timing for the Q->SI paths which creates longer project schedules and costs, and 2. if decided not to close timing for these paths suffer the penalty of increased pattern count and therefore tester time and memory.</p>
          <p className='mb-4'>To close, we would like projects to consider our solution since it is 1) automatic, 2) easy to implement and 3) will reduce pattern count and tester time when compared to the same scan insertion flow that doesn’t use our solution. The solution is proven and is currently used by two projects.</p>

          <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Guy Regev is a co-founder and Managing Partner of AlephZero Consulting. He is a veteran of the IC design Industry, with over 20 years of both management and hand-on expertise across all technical disciplines of chip and FPGA design. Extensive experience managing cross-functional HW/SW/FW international projects as well as hands-on experience through all aspects of the chip design/FPGA and productizing, with a proven track record of successful, time-crunched tape-outs and market intros of flagship products. He is also an Expert Witness for cases that involve hardware, chip, IC Design, SoC Design or FPGA design, as well as EDA tools, software, and embedded firmware. More about him at: <a className=' text-blue-500' href='https://www.guyregev.com/'>https://www.guyregev.com/</a> </p>


        </>
      )
    },
    {
      id: 8,
      title: "A Methodology for Timely Verification of a Complex SoC/CHIP",
      image: blog8_image,
      content: (
        <>

          <p className='mb-4'>This paper presents a novel and alternative methodology of logic or functional verification of a system-on-a-chip integrated- circuit. This methodology was used by our company for a successful and timely tape-out of our SoC. We will show a complete verification methodology that resulted in a fully- functional first silicon and quick time to market. It is a methodology to be used only in a very highly programmable system on a chip or very configurable ASIC, that a hardware bug can relatively easily be mitigated via a software or firmware workaround. </p>
          <h2 className='font-medium text-lg mb-4 mt-4'>I. INTRODUCTION </h2>
          <p className='mb-2'>As transistor sizes are shrinking exponentially in conformance with Moore’s law, silicon chips or ICs are increasing in density, complexity and in the amount of logic that can be run with the same rate. </p>
          <p className='mb-2'>As a result of this, many of the current ICs incorporate huge amounts of logic and contain logic that was previously spread over several different ICs. This growth introduced what the system-on-a-chip or SoC. The SoC is basically a complete system integrated on a single silicon chip or IC. </p>
          <p className='mb-2'>Unfortunately with the increase in density and integration, together with the shrinking sizes of ICs, requirements for a reduced schedule and the aggressive time to market constraints become apparent. </p>
          <p className='mb-2'>These requirements, along with the cost constraints pose a very difficult challenge to IC design teams. IC design teams are now dealing with huge amounts of logic to design, verify, and layout, without the added head count or schedule time required to support it. </p>
          <p className='mb-2'>Due to these constraints IC design projects face many challenges, one of which is in verification. How to verify such very large scale integrated SoCs is still under debate in the world IC verification community. </p>
          <p className='mb-2'>We will present the verification methodology which we used in order to verify our current 3G UMTS/HSPA+ modem SoC chip for femto-cell. </p>

          <h2 className='font-medium text-lg mb-4 mt-4'>II. THE DIFFERENCE BETWEEN SoC AND ASIC </h2>
          <p className='mb-2'>An ASIC (Application Specific Integrated Circuit) is usually a silicon chip that performs several dedicated functions, and is used for specific applications. </p>
          <p className='mb-2'>A SoC is a complete system on a chip. For example: a mother board containing a central processor (CPU) controlling several peripherals such as DDR, additional memories, USB, graphics accelerator, etc. is integrated into a single chip called SoC. A complex SoC usually contains at least one processor and many peripherals. </p>
          <p className='mb-2'>To make it even more complex, usually many of these peripherals are designed in-house and contain complex interfaces between each other (which may bypass the processor), in addition to a standard processor bus interface.   </p>
          <p className='mb-2'>A SoC is usually a chip, which unlike an ASIC, is not functional without dedicated software which controls the peripherals, configures them and activates the chip correctly. </p>
          <p className='mb-2'>A SoC design group requires – in addition to the chip architecture group – a system architecture group which defines and designs the system. The size and complexity of a SoC poses many difficulties on verification. </p>
          <p className='mb-4'>The regular methods of exhaustively checking every possible combination of hardware states is not applicable in the case of a complex SoC due to design size. This translates into very long simulation run times, and time to market which usually puts stiff schedule requirements on the project. </p>

          <h2 className='font-medium text-lg mb-4 mt-4'>III. THE PRC6000 SoC </h2>
          <p className='mb-2'>The PRC6000 architecture block diagram is depicted in Figure 1. The PRC6000 SoC is a powerful, highly integrated baseband processor specifically designed for 3G femtocells. </p>
          <p className='mb-2'>The PRC6000 contains a 3G layer 1 powerful modem and a high speed MIPS CPU. These are surrounded by a large set of accelerators and peripherals. The PRC6000 SoC contains two major and big sub-systems: </p>
          <p className='mb-4'>1) FLE (Femto Layer Engine) sub-system which is powered by a fast DSP core controlling the communications accelerators designed specifically to support 3G cellular base- station communications. </p>
          <p className='mb-2'>2) A CPU sub-system powered by a powerful and fast CPU that is responsible for Layer 1 control, and higher layers of protocol stack and application. </p>
          <p className='mb-2'>The FLE sub-system is controlled by a fast DSP core. The PHY block handles the physical parts of the UMTS and GSM layer1 while the Transport Layer block converts physical data to/from transport data. The data path of the Transport Layer block is directly connected to the CPU sub-system matrix (for shorter time delays). The DSP I/F (interface) block enables control of the FLE from the CPU sub-system and also allows for direct access of the DSP to the external DDR2 memories. </p>
          <p className='mb-2'>The CPU sub-system is controlled by a MIPS32 24Kc 600MHz processor. The CPU is aided by a DMA controller (DMAC) to control and pass data to/from a set of peripherals and external memory controllers. These peripherals are divided into fast peripherals operating on the AHB bus and slow peripherals operating on the APB bus. </p>
          <p className='mb-2'>The blocks inside the FLE phy and transport sections are not described due to Intellectual Property reasons. They consist of the cellular modem and are very complex. </p>

          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog8_fig1} alt='blog8' />
            {/* Add a caption to the image */}
            {/* <figcaption className="text-sm text-gray-500 italic">Figure 3 - Optimized Custom Shared wrapper cell. (Default structure used for SLOS designs from TCL script)</figcaption> */}

          </figure>
          
          <p className='mb-2'>In addition, they are all connected to the DSP via the z- bus and the bus bridge. They interface with each other through proprietary interfaces. The CPU sub-system also interfaces directly with blocks inside the FLE sub-system through standard interfaces, as well as a dedicated proprietary interface between the two sub-systems. </p>
          <p className='mb-4'>Due to the large size of the design, its complexity, limited engineering resources and demanding schedule, a non-traditional verification methodology had to be used. We will describe the methodology and tools we developed in the following sections. </p>

          <h2 className='font-medium text-lg mb-4 mt-4'>IV. THE METHODOLOGY </h2>
          <p className='mb-4'>As complexity and size of ICs in general and SoCs in particular increased, many verification methodologies and industrial dedicated tools were developed. The question “Which methodology to pick?”, still baffles many verification engineers and VLSI experts. Each methodology comes with a cost in terms of resources, schedule, and computing resources [1]. The verification team has to factor in all of the constraints and still come up with a methodology that will lower the risk of taping out a chip with critical bug(s). The traditional methodology is to write a test plan to cover all possible permutations of every state in the design and write dedicated direct tests to cover all of these. Adding code and functional coverage, as well as exhaustive random tests probably achieves almost 100% coverage. </p>
          <p className='mb-4'>The major drawback of this ideal system is that it is not applicable in verifying SoCs. SoCs are too big and complex to cover all permutations of all states. In addition to this, most SoC projects are very limited in computing resources and engineering resources (man-power) and just cannot afford this kind of methodology. However, they also cannot afford the impact of a re-spin (another tapeout) as a result of a critical bug found in first silicon. For these reasons, we developed a system/sw based verification methodology. The following sub-sections describe in detail the methodology we used to verify and tapeout a fully-functional chip within a very tight schedule – a single calendar year from conception to tapeout. </p>
          
          <h2 className='font-medium text-lg mb-4 mt-4'>A. SystemBasedVerification </h2>
          <p className='mb-2'>Unlike many verification methodologies which are either functional driven, feature driven, or both, we use a system driven methodology. The system driven methodology begins with the work of the verification team. </p>
          <p className='mb-2'>Our verification engineers are basically system design engineers. The same system design engineer, who defined the sub-system of a single block, will also be the one who verifies the block. The ASIC designer of the unit works hand in hand with the system engineer, who is responsible of writing the test plan and executing it. This is different from most silicon companies, in which a dedicated verification team is usually in charge of the verification and the system team is in charge only of defining the system. </p>
          <p className='mb-2'>Since the system engineer is system and software oriented, the test plan he is responsible for will aim to verify the system and not the hardware, i.e. functional or feature coverage is not the metric in this case, but system coverage is. </p>
          <p className='mb-2'>So, basically, the tests are directly aimed to verify the system’s functionality and not the hardware functionality. We use this method to narrow down the permutations we need to check, since, instead of verifying every possible combination in our hardware, we only aim to verify those that are used in our system. </p>
          <p className='mb-4'>By using this method we significantly reduce the amount of tests needed, debug time, and the verification schedule. We also narrow down the “real” bug rate since we are specifically aiming to find relevant bugs and not other bugs which we consider to be “non-real”, in the sense that they do not “really” impact the system functionality or will ever occur under real system operation conditions. </p>

          <h2 className='font-medium text-lg mb-4 mt-4'>B. SWdrivenflow </h2>
          <p className='mb-2'>Almost every SoC operation relies heavily on SW. This infers, that the SoC consists of many hardware peripherals which the DSP or CPU programs and controls, and which serve as co-processor accelerators, whose purpose is to offload the DSP or CPU from performing tasks which are very mips (million instruction per second) demanding and can be more efficiently performed by hardware. </p>
          <p className='mb-2'>Our verification methodology and tools were developed with real/operational software design in mind. Since the system designers know how to control and configure the system, and since main vehicle for doing that is the DSP core in the FLE sub-system, the block level tests were written as close as possible to operational software/firmware. This provided our verification with two main advantages: </p>
          <p className='mb-2'>1) It was afterwards feasible to use some of the code written for verification as real operational code during FW development. </p>
          <p className='mb-2'>2) Higher priority bug detection was possible – those which will prevent the system from working under operational conditions, i.e. bugs which will prevent us from using the operational firmware, which we write and control.</p>
          <p className='mb-2'>On top of making our verification flow FW/SW oriented, our system design is very robust in terms of preparing software hooks that will help us circumvent hardware bugs using software. This enables us with higher probability to productize the first tapeout of the SoC silicon even if we find bugs in silicon. </p>
          <p className='mb-4'>With this design methodology in mind, we will show how to prioritize the tests, in order to come up with a verification process which is highly manageable in risk, schedule, and effort. </p>
          
          <h2 className='font-medium text-lg mb-4 mt-4'>C. The Test Plan </h2>
          <p className='mb-2'>“The increasing complexity of today's designs has only served to further intensify the pain of functional verification. Any strategy for success here must include a verification test plan - one that trades brute force with finesse. In so doing, not only is the pain reduced, but additional benefits are quickly derived, such as greater predictability, more aggressive innovation and late stage spec changes which can be made with confidence.” [2] For these very reasons, we decided to construct our test plan as follows: The system designer – who is now functioning as the block verification engineer – will first write a verification plan. </p>
          <p className='mb-2'>This verification plan will be divided into a number of sections denoting a different system mode/scenario. Each section contains as many tests as necessary in order to properly cover the particular system mode/scenario. Logically, the system engineer will emphasize in the test plan those tests, which cover system scenarios that the SW cannot execute in case of a crucial bug. </p>
          <p className='mb-2'>Moreover, the test plan will include as many tests as possible which are based on real stimuli and reference vectors which comply with the standard on which the system is based (3GPP standard in our case). This is taken from test equipment which is qualified to for use specifically for 3GPP-3G communications. </p>
          <p className='mb-4'>During the verification cycle, the test plan will go through several reviews and be approved prior to execution. </p>

          <h2 className='font-medium text-lg mb-4 mt-4'>D. Setting The Test Priorities </h2>
          <p className='mb-2'>After the test plan is completed, it would go through test prioritization. Setting the test priorities is never a simple task and much responsibility lies on getting the priorities right since the priorities set the order of the test plan execution and debug. </p>
          <p className='mb-2'>The priorities range from “must have” tests which are the highest priority tests to “nice to have” tests which will be written only if the schedule allows for it. We used the following guidelines to prioritize the tests. </p>
          <p className='mb-2'>The test is rendered “must have” if the following conditions are met: </p>
          <p className='mb-2'>1) The test is designated to check a crucial system scenario, process or feature. </p>
          <p className='mb-2'>2) This scenario must be hardware exclusive, i.e. if SW can replace the hardware in this scenario, or circumvent a bug in this hardware, this test will not be given the highest priority but one below it instead.</p>
          <p className='mb-2'>The test is categorized under “nice to have” if the test aims to check a non-crucial system scenario and this scenario can be executed in SW. </p>
          <p className='mb-2'>All the tests with priority above “nice to have” must be written and executed by order of priority, within the verification schedule. </p>
          <p className='mb-2'>This method gives us the advantage of developing, in the right order; the most important and focused tests first, in order to check those parts of the system’s hardware that cannot be worked-around in the case of a silicon bug. </p>
          <p className='mb-4'>The resulting test-suite (after test plan implementation) is very comprehensive, and covers the hardware not only in terms of the traditional code/functional metrics but also in terms of system coverage, which make it more robust and more efficient both in test length and effort of test development. </p>
          
          <h2 className='font-medium text-lg mb-4 mt-4'>E. Bottom Up Methodology </h2>
          <p className='mb-2'>We decided to use a bottom up methodology for our verification environment. Therefore, we started by verifying a system block according to the test plan. We decided to use SystemC for our test-bench environment [3] as depicted in Figure 2 for the following reasons:</p>
          <figure className="mb-4 text-center">
            <img className="rounded mx-auto" src={blog8_fig2} alt='blog8' />
            {/* Add a caption to the image */}
            {/* <figcaption className="text-sm text-gray-500 italic">Figure 3 - Optimized Custom Shared wrapper cell. (Default structure used for SLOS designs from TCL script)</figcaption> */}

          </figure>
          
        <p className='mb-2'>1) We could not afford to wait for system or chip level integration before we began the verification process. We wanted to start verifying the block immediately after (and sometimes even before) RTL0 – our first RTL readiness milestone, was declared. </p>
        <p className='mb-2'>2) No less important than the first reason, is an easy migration to system level (DSP level) tests. Since we write our tests in C++ we prepared several user functions for the purpose of writing and reading system-block registers, and for driving and sampling interface signals. Any code that was related to DSP transactions was easily translated into real DSP code once we moved it into the integrated FLE sub-system verification environment. </p>
        <p className='mb-2'>3) We wanted the code that was used for verification to be as close as possible to the real FW operational code we would write for the DSP. Writing in C and C++ directly accomplished this goal, resulting in code that can later be used as the baseline for operational FW. We also used Matlab vectors which were proven by the standard by using pre-validated vectors taken from 3GPP 3G compliant test equipment. </p>
        
        <h2 className='font-medium text-lg mb-4 mt-4'>F. IntegrationTests </h2>
        <p className='mb-2'>As shown in Fig. 1, our SoC contains few internal and proprietary interfaces which we needed to thoroughly verify. For interfaces between different system blocks inside the FLE sub-system, we used the same method as depicted in Figure 2, with the exception that those two system blocks were wrapped by a SystemC testbench. We tested these in the block level environment, and then easily ported the environment to system level in the same way used for a single system block. </p>
        <p className='mb-2'>The proprietary interfaces which cross the FLE sub- system boundaries, such as chip IO interfaces or interfaces between the FLE sub-system and the CPU sub-system were verified directly in the full-chip level environment by writing dedicated tests which exhaustively check them. </p>
        
        <h2 className='font-medium text-lg mb-4 mt-4'>G. Common Library </h2>
        <p className='mb-2'>During RTL development, we used a rich set of common RTL library modules, which we developed in-house. Elements such as smart registers, up-dn counters, PN generators, barrel-shifters etc., were pre-designed and pre- verified on a stand-alone basis. During RTL coding, the use of this common library was mandatory for all designers. By pre-verifying the most common RTL building blocks, we limited the amount of low-level hardware bugs. In addition, even if a bug was found at a low-level common module, fixing it only had to be done in one place – the common library – in order to solve it across the entire design. </p>
        
        <h2 className='font-medium text-lg mb-4 mt-4'>H. Use Of Verified IPs </h2>
        <p className='mb-2'>Our CPU sub-system was IP-core based. That is, we integrated the MIPS 24K processor core with pre-verified IP cores (the encryption blocks are the exception to this, as they were developed in-house), using standard industry bus architectures such as OCP/AHB/APB. This sub-system, was easier to verify, since, it resembled the “classical” system, consisting of the integration of IP components on a standard bus architecture [4]. </p>
        <p className='mb-2'>Our basic assumption for verification – which is true by definition – is that IP-Cores are pre-designed, pre-verified hardware blocks [5]. Our verification strategy in the CPU sub-system was therefore much less complicated than the FLE sub-system case. We therefore focused our efforts almost entirely on integration testing, whilst verifying the inter-block integration, thus saving significant verification resources, time and effort. </p>
        
        <h2 className='font-medium text-lg mb-4 mt-4'>V. SUMMARY AND CONCLUSIONS</h2>
        <p className='mb-2'>The methodology we described in detail enabled us to efficiently verify our very complex SoC, which is composed of two major sub-systems and some complex proprietary interfaces. By utilizing this methodology, we succeeded in taping out our very complex SoC IC within one year of conception, and having it functionally correct the first time. </p>
        <p className='mb-2'>We attribute much of this success to our chosen verification methodology with emphasis on the following main aspects. </p>
        <p className='mb-2'>1) The system-based verification as opposed to functional or feature based verification. It is significant to note, it is the use of our system engineers as verification engineers forms the basis of our methodology. It is what allows for our methodology to be a truly practical and efficient methodology that produces very efficient results. </p>
        <p className='mb-2'>2) Our test writing (as close to real FW as possible, and using standard based vectors as stimuli and reference) and test prioritization method, which enabled us to pin-point system bugs and detect them within our schedule. </p>
        <p className='mb-2'>3) The flexible SystemC based verification environment, which enabled a very easy and automatic migration from system-block level tests to sub-system and full-chip level environment. </p>
        
        <h2 className='font-medium text-lg mb-4 mt-4'>REFERENCES </h2>
        <p className='mb-2'>[1] Bacchini, Francine; Hu, Alan J.; Fitzpatrick, Tom; Ranjan, Rajeev; Lacey, David; Tan, Mercedes; Piziali, Andrew; Ziv, Avi, “Verification Coverage: When is Enough, Enough?,” Design Automation Conference, 2007. DAC '07. 44th ACM/IEEE, pp. 744 - 745. </p>
        <p className='mb-2'>[2] Bacchini, F.; Malik, S.; Bergeron, J.; Foster, H.; Piziali, A.; Mitra, R.S.; Ahlschlager, C.; Stein, D., “Building a verification test plan: trading brute force for finesse,” Design Automation Conference, 2006 43rd ACM/IEEE, pp. 805-806. </p>
        <p className='mb-2'>[3] Yarom, I.; Glasser, G., “SystemC opportunities in chip design flow,” in Proceedings of the 2004 11th IEEE International Conference on Electronics, Circuits and Systems, December 13-15, 2004, pp. 507- 510. </p>
        <p className='mb-2'>[4] Deshpande, A., “Verification of IP-Core Based SoC’s,” in 9th International Symposium on Quality Electronic Design, March 17- 19,2008, pp. 433-436. </p>
        <p className='mb-2'>[5] Guy Mosensoson., “Practical Approaches to SOC Verification” Verisity Design, Inc. </p>

        <h1 className='font-black text-xl mt-8'>About the Author:</h1>
          <p className="mb-4 font-medium">Guy Regev is a co-founder and Managing Partner of AlephZero Consulting. He is a veteran of the IC design Industry, with over 20 years of both management and hand-on expertise across all technical disciplines of chip and FPGA design. Extensive experience managing cross-functional HW/SW/FW international projects as well as hands-on experience through all aspects of the chip design/FPGA and productizing, with a proven track record of successful, time-crunched tape-outs and market intros of flagship products. He is also an Expert Witness for cases that involve hardware, chip, IC Design, SoC Design or FPGA design, as well as EDA tools, software, and embedded firmware. More about him at: <a className=' text-blue-500' href='https://www.guyregev.com/'>https://www.guyregev.com/</a> </p>



        </>
      )
    }
  ];

  const renderBlogList = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="p-6 bg-white border rounded shadow cursor-pointer"
          onClick={() => setSelectedBlog(blog.id)}
        >
          <h2 className="text-2xl mb-4 font-semibold">{blog.title}</h2>
          <img className="mb-4 rounded h-[250px] object-cover" src={blog.image} alt={blog.title} />
          {/* <div>{`${blog.content.props.children[0].props.children.substring(0, 50)}...`}</div> */}
        </div>
      ))}
    </div>
  );

  const renderExpandedBlog = (blog) => (
    <div className="max-w-4xl mx-auto bg-white p-6 rounded shadow">
      <button
        className="mb-4 bg-blue-500 text-white rounded py-2 px-4"
        onClick={() => setSelectedBlog(null)}
      >
        Back
      </button>
      <h2 className="text-3xl mb-4 font-semibold">{blog.title}</h2>
      {/* <img className="mb-4 rounded" src={blog.image} alt={blog.title} /> */}
      <div className='text-justify'>{blog.content}</div>
      <button
        className="mb-4 bg-blue-500 text-white rounded py-2 px-4"
        onClick={() => setSelectedBlog(null)}
      >
        Back
      </button>
    </div>
  );

  return (
    <div className="p-10 bg-gray-100 min-h-screen flex flex-col items-center">
      <h1 className="text-4xl mb-10 text-center w-full">{selectedBlog ? 'Read Blog' : 'Blog'}</h1>
      {selectedBlog ? renderExpandedBlog(blogs.find(blog => blog.id === selectedBlog)) : renderBlogList()}
    </div>
  );
};

export default Blog;
