import React from 'react';
import { doc, setDoc } from "firebase/firestore"; 
import { GoogleAuthProvider, signInWithPopup   } from 'firebase/auth';
import { auth } from '../firebase';
import {db} from '../firebase';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

const GoogleSignIn = () => {
  const {dispatch} = useContext(AuthContext);
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
   signInWithPopup(auth,provider)
      .then(async (result) => {
        const user = result.user;
        dispatch({type:"LOGIN", payload:user})
        console.log(user);
       
        await setDoc(doc(db, "subscribers", user.uid), {name: user.displayName, email: user.email, type: "google sign in"});
        navigate("/");
      }).catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <div className="flex items-center justify-center  bg-gray-50 py-2 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <button 
            onClick={signInWithGoogle} 
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Google Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleSignIn;
