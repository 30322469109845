import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import {Home} from './pages/home';
import { Footer } from "./components/footer";
import { Login } from "./components/login";
import { SignUp } from "./components/signup";

import { About } from "./pages/about";
import { Services } from "./pages/services";
import { Papers } from "./pages/papers";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";
import { AuthContextProvider } from "./context/AuthContext";
import ContactUs from "./pages/contactus";
import Subscribe from "./pages/subscribe";
import ForgotPassword from "./pages/forgetPassword";
import { VideoPage } from "./pages/demoVideos";
import { AdminDashboard } from "./pages/adminDashboard";
import { NotFound } from "./components/404";
import { Navbar } from "./components/topNavBar";
import Blog from "./pages/blog";
import WaitlistPage from "./pages/waitlist";

function App() {
  const {currentUser} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  
  return (
    <AuthContextProvider>
    <Router>
        {/* <TopNavBar></TopNavBar>  */}
        <Navbar/>
        
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>}/>
            <Route path="/login" element={<Login/>} />
            <Route path="/signup" element={<SignUp/>} />
            <Route path="/contactUs" element={<ContactUs/>} />
            <Route path="/subscribe" element={<Subscribe/>} />
            <Route path="/forgetPassword" element={<ForgotPassword/>} />
            <Route path="/demoVideos" element={<VideoPage/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/papers" element={<RequireAuth><Papers/></RequireAuth>}/>
            <Route path="/admin" element={<RequireAuth><AdminDashboard/></RequireAuth>}/>
            <Route path="*" element={<NotFound/>} />
            {/* <Route path="/waitlist" element={<WaitlistPage/>}/> */}
        </Routes>
       
        <Footer></Footer>
      </Router>
      </AuthContextProvider>
  );
}

export default App;
