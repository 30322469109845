import React, { useState, useEffect } from 'react';


export function Home() {
    const [text, setText] = useState('');
    const fullText = "Generative AI | Radar | ASIC | FPGA | Computer Vision | LLM";

    useEffect(() => {
        if (text.length < fullText.length) {
            setTimeout(() => {
                setText(fullText.slice(0, text.length + 1));
            }, 100); // delay between characters appearing
        }
    }, [text]);

    return (
        <div className="relative h-screen overflow-hidden">
            <video autoPlay loop muted id="background-video" aria-hidden="true" poster="bk_poster_image.png" preload="auto" className="absolute z-10 min-w-full min-h-full w-full h-full object-cover">
                <source src="bk_v2.webm" type="video/webm" />
            </video>

            <div className="absolute z-20 text-white flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50">
                <div>
                    <h1 className="text-5xl font-semibold">{text}</h1>
                    {/* <p className="text-xl">Our dedicated team of engineers tackles complex and challenging technology problems, making a difference through innovative solutions.</p> */}
                    <div className="text-center p-8">
  {/* <h1 className="text-4xl font-bold mb-6">Unlock the Power of AI Technologies</h1> */}
  <p className="text-lg mb-1">Expert in PyTorch, TensorFlow, TensorFlow-Lite, Model Pruning, and Quantization.</p>
  {/* <p className="text-lg mb-4">We develop robust code using packages like TensorFlow, TensorFlow-Lite (including quantization), PyTorch (ONNX conversion), and TinyML.</p> */}
  <p className="text-lg mb-4">Specializing in prototyping algorithms in MATLAB or Python and leveraging cutting-edge technologies such as the Google TPU.</p>
</div>



                </div>

                <div class="flex mt-8 flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <a href="/contactUs" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                        Contact now
                        <svg aria-hidden="true" class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </a>
                    <a href="/subscribe" class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                        Subscribe to newsletter
                    </a>
                </div>
            </div>
        </div>
    );
};

